jQuery.noConflict();
(function( $ ) {
    $(function() {
        $( document ).ready(function() {


            //mobile menu toggle
            $(".js-toggleMenuItem").click(function(){
                $("ul.c-site-menu__list--d2").toggle();
              });


            //simple tab switcher for .c-tabs
            $('.js-activateTab').on('click', function(e) {
                e.preventDefault();
                var $clicked = $(this);

                if (!$clicked.hasClass('is-active')) {

                    var clickedSelector = $clicked.attr('href'),
                        $tabs = $clicked.parents('.c-tabs'),
                        $tabsNav = $tabs.find('.c-tabs__nav'),
                        $oldTab = $tabs.find('.c-tabs__tab.is-active'),
                        $nextTab = $(clickedSelector);

                    $("html, body").animate({ scrollTop: $tabs.offset().top }, 500);

                    $tabsNav.find('.is-active').removeClass('is-active');
                    $clicked.addClass('is-active');

                    $oldTab.fadeOut(500, function() {
                        $oldTab.removeClass('is-active');

                        $nextTab.fadeIn(500, function() {
                            $nextTab.addClass('is-active');
                        })
                    });
                }
            })



            $('.js-inlineLightbox').magnificPopup({
                type: 'inline',
                mainClass: 'mfp-inline',
                callbacks: {
                    elementParse: function(item) {
                        window.location.hash = item.src;
                    }
                }
            });


            var hash = window.location.hash;

            if (hash) {
                $("html, body").animate({ scrollTop: $('#openings').offset().top }, 0, function() {
                    $(".js-inlineLightbox[href$='" + hash + "']").click();
                });
            }

            if ($('.js-careers').length) {
                $.ajax( "/ajax/careers.php" ).done( function(response) {
                    if (response.length) {
                        careers = $.parseJSON(response);
                        $.each(careers, function(index, career) {
                            $('.js-careers').append('<li class="c-careers-list__item"><a class="c-careers-list__link" href="'+career.url+'"><strong class="c-careers-list__title">'+career.title+'</strong><span class="c-careers-list__location">'+career.location+'</span></a></li>');
                        });
                    } else {
                        //console.log('No data');
                    }
                } );
            }



        });

    });
})(jQuery);
